/**
 * Config and docs at:
 * https://github.com/orangehill/bootstrap-session-timeout
 */
$.sessionTimeout({
    message: 'Interact with the system to reset your session.',
    keepAlive: false,
    logoutUrl: '/logout?logoutType=timeout',
    redirUrl: '/logout?logoutType=timeout',
    warnAfter: 600000,   // 10min.
    redirAfter: 900000,  // 15min.
    countdownSmart: true,
    countdownMessage: 'Logging off in {timer}.',
    countdownBar: true,
    disableTimer: false
});

